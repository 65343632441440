import { LoggerFactory, ILogger } from '../../logger';
import { ILoggerConfiguration } from '../../logger/models';
import { ILoggerConfigurationProvider } from '../../logger/interfaces';
import { ILibraryManager } from './ILibraryManager';
import { ILibrary } from './ILibrary';
import { Library } from './library';
import { LogLevelMap, MinLevelToLog } from '../../logger/enums';
import { IClientSettings, HostedClientLoadedEvent } from '@sparkware/uc-sdk-core';

class LibraryManager implements ILibraryManager {
    readonly ClientsFrameworkLibrary: ILibrary<void>;
    readonly UserareaLibrary: ILibrary<void>;
    readonly UCSDKLibrary: ILibrary<unknown>;
    readonly UCSDKEntryLibrary: ILibrary<unknown>;
    readonly ClientLibrary: ILibrary<HostedClientLoadedEvent<IClientSettings>>;

    constructor(logger: ILogger) {
        this.ClientsFrameworkLibrary = new Library('ClientsFramework', logger);
        this.UserareaLibrary = new Library('Userarea', logger);
        this.UCSDKLibrary = new Library('UCSDK', logger);
        this.UCSDKEntryLibrary = new Library('UCSDKEntry', logger);
        this.ClientLibrary = new Library('Client', logger);
    }
}

class LoggerConfigurationProvider implements ILoggerConfigurationProvider {
    public get = (): ILoggerConfiguration => {
        const { defaultLogLevel = 'error' } = pageContextData.environment;
        const _defaultLogLevel = MinLevelToLog[LogLevelMap[defaultLogLevel]];
        return {
            MinLogLevel: Number(localStorage.getItem('UC.MINLOGLEVEL') || _defaultLogLevel),
        };
    };
}

const logger = LoggerFactory.create(
    'LibraryManager',
    window.console,
    new LoggerConfigurationProvider(),
);

const Instance: ILibraryManager = new LibraryManager(logger);

export const {
    ClientsFrameworkLibrary,
    UCSDKEntryLibrary,
    UCSDKLibrary,
    UserareaLibrary,
    ClientLibrary,
} = Instance;

export default Instance;
