import DeferredObject from '../../../../Modules/Utils/DeferredObject';
import { ILibrary } from './ILibrary';
import { ILogger } from '../../logger';

export class Library<T> implements ILibrary<T> {
    private readonly _logger: ILogger;

    private readonly _deferred: DeferredObject<T>;
    private readonly _name: string;

    public get name(): string {
        return this._name;
    }

    public get isReady(): boolean {
        return this._deferred.resolved;
    }

    public get ready(): Promise<T> {
        return this._deferred.promise;
    }

    constructor(name: string, logger: ILogger) {
        this._name = name;
        this._logger = logger;
        this._deferred = new DeferredObject<T>({ storeResolved: true });
    }

    public loaded(value: T): void {
        this._logger.debug(`Library '${this._name}' has succesfully loaded`);

        this._deferred.resolve(value);
    }

    public failed(error: Error): void {
        this._logger.error(
            `Library '${this._name}' failed to load, error : '${JSON.stringify(error)}'`,
        );

        this._deferred.reject(error);
    }
}
